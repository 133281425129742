import request from '../request'
import axios from 'axios'
import qs from 'qs';
const token = localStorage.getItem('Blade-Auth-Token');
// export const login = (params) => {
//     return axios.post(`/api/blade-party-admin/dict-biz/dictionary${params.param}`, params)
//         .then(res => res)
//         .catch(err => { console.log(err) });
// };
//请求普通用户列表
export const getWishList = (params) => {
    return request({
        url: `/blade-party-admin/min/wish/list`,
        data: params,
        method: 'POST',
        headers: {
            'Blade-Auth': token,
            'User-Type': 'DINGTALK'
        }
    })
};

//请求管理员看到的列表
export const getAuditList = (params) => {
    return request({
        url: `/blade-party-admin/min/wish/audit/list`,
        data: params,
        method: 'POST',
        headers: {
            'Blade-Auth': token,
            'User-Type': 'DINGTALK'
        }
    })
};

//进入详情
export const detail = (params) => {
    return request({
        url: `/blade-party-admin/min/wish/detail`,
        data: params,
        method: 'POST',
        headers: {
            'Blade-Auth': token,
            'User-Type': 'DINGTALK'
        }
    })
};

//许愿
export const toWish = (params) => {
    return request({
        url: `/blade-party-admin/min/wish/vow`,
        data: params,
        method: 'POST',
        headers: {
            'Blade-Auth': token,
            'User-Type': 'DINGTALK'
        }
    })
};

//认领心愿
export const toget = (params) => {
    return request({
        url: `/blade-party-admin/min/wish/claim`,
        data: params,
        method: 'POST',
        headers: {
            'Blade-Auth': token,
            'User-Type': 'DINGTALK'
        }
    })
};

// 文件上传
export const uploadFile = (params) => {
    return axios.post(`/blade-party-admin/file/put-file`, params, { headers: { 'User-Type': 'DINGTALK', 'Blade-Auth': token, 'Content-Type': 'multipart/form-data' } });
};

// 审核
export const requestChecked = (params) => {
    return request({
        url: `/blade-party-admin/min/wish/audit`,
        data: params,
        method: 'POST',
        headers: {
            'User-Type': 'DINGTALK',
            'Blade-Auth': token
        }
    })
};

// 完成
export const requestFinish = (params) => {
    return request({
        url: `/blade-party-admin/min/wish/complete`,
        data: params,
        method: 'POST',
        headers: {
            'User-Type': 'DINGTALK',
            'Blade-Auth': token
        }
    })
};

//钉钉鉴权
export const requestConfig = (params) => {
    return request({
        url: `/blade-party-admin/min/dingtalk/config`,
        params: params,
        method: 'GET',
        headers: {
            'User-Type': 'DINGTALK',
            'Blade-Auth': token
        }
    })
};
